import React from "react";
import Fade from 'react-reveal/Fade';

import ContactForm from "./ContactForm";
import './Contact.css'



const Contact = () => {

    return (
      <>

<div className='contact-hero main-menu'>

            <div className="contact-hero-content">
            <Fade top>
                    <h1>HAVE SOME QUESTIONS?</h1>
            </Fade>
            <Fade right>
                  <p>Have questions or want to learn more about our TMS therapy services? We look forward to being a part of your mental health journey.</p>
            </Fade>
            <Fade right>
                  <a href="tel:323-319-6219"><h2>+1 (323) 282-5838</h2></a>

            </Fade>

            </div>
     </div>

      <ContactForm />

      </>
    )
}

export default Contact