import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import './PHQ9.css';
import Therapist from '../../assets/therapist_taking_notes.webp'
import { Link as LinkRoll } from "react-scroll";

const PHQ9 = () => {
  const form = useRef();
  const questions = [
    "Little interest or pleasure in doing things?",
    "Feeling down, depressed, or hopeless?",
    "Trouble falling or staying asleep, or sleeping too much?",
    "Feeling tired or having little energy?",
    "Poor appetite or overeating?",
    "Feeling bad about yourself — or that you are a failure or have let yourself or your family down?",
    "Trouble concentrating on things, such as reading the newspaper or watching television?",
    "Moving or speaking so slowly that other people could have noticed? Or the opposite — being so fidgety or restless that you have been moving around a lot more than usual?",
    "Thoughts that you would be better off dead, or thoughts of hurting yourself in some way?"
  ];

  const [responses, setResponses] = useState(Array(9).fill(null)); // Use null for uninitialized state
  const [formData, setFormData] = useState({
    user_name: '',
    user_phone: '',
    user_email: ''
  });
  const [showResult, setShowResult] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState("Submit");

  const handleResponseChange = (index, value) => {
    const newResponses = [...responses];
    newResponses[index] = parseInt(value);
    setResponses(newResponses);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleShowResult = () => {
    // Check if all questions are answered
    if (responses.includes(null)) {
      alert('Please answer all questions before showing the result.');
    } else {
      setShowResult(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all questions are answered again before submission
    if (responses.includes(null)) {
      alert('Please answer all questions before submitting.');
      return;
    }

    const totalScore = calculateTotalScore();
    const interpretation = getScoreInterpretation(totalScore);
    const recommendation = getRecommendation(totalScore);

    const templateParams = {
      user_name: formData.user_name,
      user_phone: formData.user_phone,
      user_email: formData.user_email,
      phq9_score: totalScore,
      phq9_interpretation: interpretation,
      phq9_recommendation: recommendation
    };

    console.log(templateParams);

    emailjs.sendForm('service_bdfl0ie', 'template_nu7qcqh', form.current, 'CLcHWAKSemVMd1_sU')
      .then((result) => {
        console.log("Email successfully sent:", result.text);
        alert('PHQ-9 form successfully submitted!');

        // Clear form data and responses
        setResponses(Array(9).fill(null));
        setFormData({
          user_name: '',
          user_phone: '',
          user_email: ''
        });
        setShowResult(false);
        setSubmitButtonText("Score Submitted");

        // Reset button text after 3 seconds
        setTimeout(() => {
          setSubmitButtonText("Submit");
        }, 3000);
      }, (error) => {
        console.error("Error sending email:", error.text);
        alert('Failed to submit form. Please try again.');
      });
  };

  const calculateTotalScore = () => {
    return responses.reduce((sum, score) => sum + (score || 0), 0);
  };

  const getScoreInterpretation = (score) => {
    if (score >= 1 && score <= 4) {
      return "Minimal depression";
    } else if (score >= 5 && score <= 9) {
      return "Mild depression";
    } else if (score >= 10 && score <= 14) {
      return "Moderate depression";
    } else if (score >= 15 && score <= 19) {
      return "Moderately severe depression";
    } else if (score >= 20 && score <= 27) {
      return "Severe depression";
    } else {
      return "No depression";
    }
  };

  const getRecommendation = (score) => {
    if (score >= 1 && score <= 4) {
      return "No significant depression. It is recommended to monitor your mood.";
    } else if (score >= 5 && score <= 9) {
      return "Mild depression. Consider lifestyle changes and self-care. Consult a healthcare provider if symptoms persist.";
    } else if (score >= 10 && score <= 14) {
      return "Moderate depression. It is recommended to seek therapy or consult a healthcare provider.";
    } else if (score >= 15 && score <= 19) {
      return "Moderately severe depression. Professional counseling and potentially medication are recommended.";
    } else if (score >= 20 && score <= 27) {
      return "Severe depression. Immediate consultation with a healthcare provider is necessary, possibly involving both therapy and medication.";
    } else {
      return "No depression detected.";
    }
  };

  return (
    <>
    <div className='phq9-bg' name='toPHQ9Result'>

      <div className='phq9 container'>

        <div className='phq9-image'>
        <h1>Patient Health Questionnaire (PHQ-9)</h1><br />
        <p>
        The PHQ-9 (Patient Health Questionnaire) assessments are provided as self-screening tools for informational purposes only. The results are intended to help you understand your mental health, but they do not replace a professional diagnosis or consultation with a licensed mental health professional. </p> <br />
        <img  src={Therapist} alt='therapist' loading='lazy'/>
        </div>

    <div className="phq9-container" name='toResult'>

      <div className='phq9-content container'>
        {!showResult ? (
          <form>
            {questions.map((question, index) => (
              <div key={index} className="phq9-question">
                <p>{index + 1}. {question}</p>
                <div className="phq9-options">
                <div className='phq9-optionsOne'>

                  <label>
                    <input
                      type="radio"
                      value="0"
                      name={`question${index}`}
                      onChange={() => handleResponseChange(index, 0)}
                      required
                    />
                    Not at all
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="1"
                      name={`question${index}`}
                      onChange={() => handleResponseChange(index, 1)}
                      required
                    />
                    Several days
                  </label>
                  </div>

                  <div className='phq9-optionsTwo'>
                  <label>
                    <input
                      type="radio"
                      value="2"
                      name={`question${index}`}
                      onChange={() => handleResponseChange(index, 2)}
                      required
                    />
                    More than half the days
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="3"
                      name={`question${index}`}
                      onChange={() => handleResponseChange(index, 3)}
                      required
                    />
                    Nearly every day
                  </label>
                  </div>
                </div>
              </div>
            ))}
            <LinkRoll
                activeClass="active"
                to="toResult"
                spy={true}
                smooth={true}
                duration={500}
              >
            <button type="button" className="phq9-result-btn" onClick={handleShowResult}>
              Show My PHQ-9 Result
            </button>
            </LinkRoll>
            <div className='result-disclaimer'>
            <p>After completing the assessment, you will have the option to enter your Name, Email, and Phone Number. This information will not be required to view your results. If you choose to submit your information, it will be forwarded to our expert team for further evaluation and follow-up. Your submission of personal information is entirely voluntary, and your results will remain accessible to you even if you do not provide this information.</p>
            </div>
          </form>
        ) : (
          <div className='phq9Result'>
            <h2>Your PHQ-9 Result</h2><br />
            <p>Total Score: {calculateTotalScore()}</p><br />
            <p>Interpretation: {getScoreInterpretation(calculateTotalScore())}</p><br />
            <p>Recommendation: {getRecommendation(calculateTotalScore())}</p><br />

            <table border="1">
              <tr>
                <th>GAD 7 score (points)</th>
                <th>Interpretation</th>
                <th>Recommendation</th>
              </tr>
              <tr>
                <td>0 - 4</td>
                <td>No or little anxiety</td>
                <td>Common symptoms should continue to be monitored if there are still suspicions. Follow up GAD 7 assessment in 2 weeks.</td>
              </tr>
              <tr>
                <td>5 - 9</td>
                <td>Mild anxiety disorder</td>
                <td>Patient monitoring needs to be initiated.</td>
              </tr>
              <tr>
                <td>10 - 14</td>
                <td>Moderate anxiety disorder</td>
                <td>The patient should be evaluated with other quantitative and qualitative measures of psychological/ psychiatric testing.</td>
              </tr>
              <tr>
                <td>15 - 21</td>
                <td>Severe anxiety disorder</td>
                <td>Treatment needs to be initiated if it hasn’t already.</td>
              </tr>
          </table>


            <form ref={form} onSubmit={handleSubmit}>
              <div className="phq9-input">
                <label htmlFor="user_name">Name:</label>
                <input
                  type="text"
                  id="user_name"
                  name="user_name"
                  value={formData.user_name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="phq9-input">
                <label htmlFor="user_phone">Phone Number:</label>
                <input
                  type="tel"
                  id="user_phone"
                  name="user_phone"
                  value={formData.user_phone}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="phq9-input">
                <label htmlFor="user_email">Email Address:</label>
                <input
                  type="email"
                  id="user_email"
                  name="user_email"
                  value={formData.user_email}
                  onChange={handleInputChange}
                  required
                />
              </div>

              {/* Hidden inputs for score, interpretation, and recommendation */}
              <input type="hidden" name="phq9_score" value={calculateTotalScore()} />
              <input type="hidden" name="phq9_interpretation" value={getScoreInterpretation(calculateTotalScore())} />
              <input type="hidden" name="phq9_recommendation" value={getRecommendation(calculateTotalScore())} />

              <button type="submit" className="phq9-submit-btn">{submitButtonText}</button>
              <div className='result-note'>
              <p>Please note that the information you provide will be handled with confidentiality and in accordance with our privacy policy. However, completing these assessments should not be considered a substitute for professional advice, diagnosis, or treatment. Always seek the advice of your physician or qualified mental health provider with any questions you may have regarding your mental health condition.</p>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
    </div>
    </div>
    </>
  );
};

export default PHQ9;
