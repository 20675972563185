import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import { Link as LinkRoll } from "react-scroll";

import Therapist from '../../assets/therapist_taking_notes.webp'

import './Gad7.css';

const GAD7 = () => {
  const form = useRef();
  const questions = [
    "Feeling nervous, anxious, or on edge?",
    "Not being able to stop or control worrying?",
    "Worrying too much about different things?",
    "Trouble relaxing?",
    "Being so restless that it's hard to sit still?",
    "Becoming easily annoyed or irritable?",
    "Feeling afraid as if something awful might happen?"
  ];

  const [responses, setResponses] = useState(Array(7).fill(null));
  const [formData, setFormData] = useState({
    user_name: '',
    user_phone: '',
    user_email: ''
  });
  const [showResult, setShowResult] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState("Submit");

  const handleResponseChange = (index, value) => {
    const newResponses = [...responses];
    newResponses[index] = parseInt(value);
    setResponses(newResponses);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleShowResult = () => {
    // Check if all questions are answered
    if (responses.includes(null)) {
      alert('Please answer all questions before showing the result.');
    } else {
      setShowResult(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all questions are answered again before submission
    if (responses.includes(null)) {
      alert('Please answer all questions before submitting.');
      return;
    }

    const totalScore = calculateTotalScore();
    const interpretation = getScoreInterpretation(totalScore);
    const recommendation = getRecommendation(totalScore);

    const templateParams = {
      user_name: formData.user_name,
      user_phone: formData.user_phone,
      user_email: formData.user_email,
      gad7_score: totalScore,
      gad7_interpretation: interpretation,
      gad7_recommendation: recommendation
    };

    console.log(templateParams);

    emailjs.sendForm('service_bdfl0ie', 'template_2xz3cit', form.current, 'CLcHWAKSemVMd1_sU')
      .then((result) => {
        console.log("Email successfully sent:", result.text);
        alert('GAD-7 form successfully submitted!');

        // Clear form data and responses
        setResponses(Array(7).fill(null));
        setFormData({
          user_name: '',
          user_phone: '',
          user_email: ''
        });
        setShowResult(false);
        setSubmitButtonText("Score Submitted");

        // Reset button text after 3 seconds
        setTimeout(() => {
          setSubmitButtonText("Submit");
        }, 3000);
      }, (error) => {
        console.error("Error sending email:", error.text);
        alert('Failed to submit form. Please try again.');
      });
  };

  // Function to calculate the total score
  const calculateTotalScore = () => {
    return responses.reduce((sum, score) => sum + (score || 0), 0);
  };

  // Function to get the score interpretation
  const getScoreInterpretation = (score) => {
    if (score >= 0 && score <= 4) {
      return "Minimal anxiety";
    } else if (score >= 5 && score <= 9) {
      return "Mild anxiety";
    } else if (score >= 10 && score <= 14) {
      return "Moderate anxiety";
    } else if (score >= 15 && score <= 21) {
      return "Severe anxiety";
    } else {
      return "No anxiety";
    }
  };

  // Function to get recommendation based on score
  const getRecommendation = (score) => {
    if (score >= 0 && score <= 4) {
      return "No significant anxiety. It is recommended to monitor your mood.";
    } else if (score >= 5 && score <= 9) {
      return "Mild anxiety. Consider lifestyle changes and self-care. Consult a healthcare provider if symptoms persist.";
    } else if (score >= 10 && score <= 14) {
      return "Moderate anxiety. It is recommended to seek therapy or consult a healthcare provider.";
    } else if (score >= 15 && score <= 21) {
      return "Severe anxiety. Immediate consultation with a healthcare provider is necessary, possibly involving both therapy and medication.";
    } else {
      return "No anxiety detected.";
    }
  };

  return (
    <>
<div className='gad7-bg' name='toGad7Result'>

  <div className='gad7 container'>

    <div className='gad7-image'>
        <h1>Generalized Anxiety Disorder 7 (GAD-7)</h1><br />
        <p>
        The GAD-7 (Generalized Anxiety Disorder) and PHQ-9 (Patient Health Questionnaire) assessments are provided as self-screening tools for informational purposes only. The results are intended to help you understand your mental health, but they do not replace a professional diagnosis or consultation with a licensed mental health professional. </p> <br />
        
        <img  src={Therapist} alt='therapist' loading='lazy'/>


    </div>

    <div className="gad7-container"> 

      <div className='gad7-content container'>
        {!showResult ? (
          <form>
            {questions.map((question, index) => (
              <div key={index} className="gad7-question">
                <p>{index + 1}. {question}</p>

                <div className="gad7-options">
                  <div className='gad7-optionsOne'>
                    <label>
                      <input
                        type="radio"
                        value="0"
                        name={`question${index}`}
                        onChange={() => handleResponseChange(index, 0)}
                        required
                      />
                      Not at all
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="1"
                        name={`question${index}`}
                        onChange={() => handleResponseChange(index, 1)}
                        required
                      />
                      Several days
                    </label>
                  </div>

                  <div className='gad7-optionsTwo'>
                  <label>
                    <input
                      type="radio"
                      value="2"
                      name={`question${index}`}
                      onChange={() => handleResponseChange(index, 2)}
                      required
                    />
                    More than half the days
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="3"
                      name={`question${index}`}
                      onChange={() => handleResponseChange(index, 3)}
                      required
                    />
                    Nearly every day
                  </label>
                  </div>
              </div>
              </div>
            ))}
                <LinkRoll
                activeClass="active"
                to="toGad7Result"
                spy={true}
                smooth={true}
                duration={500}
              >

            <button type="button" className="gad7-result-btn" onClick={handleShowResult}>
              Show My GAD-7 Result
            </button>
              </LinkRoll>
            <div className='result-disclaimer'>
            <p>After completing the assessment, you will have the option to enter your Name, Email, and Phone Number. This information will not be required to view your results. If you choose to submit your information, it will be forwarded to our expert team for further evaluation and follow-up. Your submission of personal information is entirely voluntary, and your results will remain accessible to you even if you do not provide this information.</p>
            </div>
          </form>
        ) : (

          <div className='gad7-result'>
            <h2>Your GAD-7 Result</h2>
            <p>Total Score: {calculateTotalScore()}</p>
            <p>Interpretation: {getScoreInterpretation(calculateTotalScore())}</p>
            <p>Recommendation: {getRecommendation(calculateTotalScore())}</p>

            
            <table border="1">
              <tr>
                <th>GAD 7 score (points)</th>
                <th>Interpretation</th>
                <th>Recommendation</th>
              </tr>
              <tr>
                <td>0 - 4</td>
                <td>No or little anxiety</td>
                <td>Common symptoms should continue to be monitored if there are still suspicions. Follow up GAD 7 assessment in 2 weeks.</td>
              </tr>
              <tr>
                <td>5 - 9</td>
                <td>Mild anxiety disorder</td>
                <td>Patient monitoring needs to be initiated.</td>
              </tr>
              <tr>
                <td>10 - 14</td>
                <td>Moderate anxiety disorder</td>
                <td>The patient should be evaluated with other quantitative and qualitative measures of psychological/ psychiatric testing.</td>
              </tr>
              <tr>
                <td>15 - 21</td>
                <td>Severe anxiety disorder</td>
                <td>Treatment needs to be initiated if it hasn’t already.</td>
              </tr>
          </table>



            <form ref={form} onSubmit={handleSubmit}>
              <div className="gad7-input">
                <label htmlFor="user_name">Name:</label>
                <input
                  type="text"
                  id="user_name"
                  name="user_name"
                  value={formData.user_name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="gad7-input">
                <label htmlFor="user_phone">Phone Number:</label>
                <input
                  type="tel"
                  id="user_phone"
                  name="user_phone"
                  value={formData.user_phone}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="gad7-input">
                <label htmlFor="user_email">Email Address:</label>
                <input
                  type="email"
                  id="user_email"
                  name="user_email"
                  value={formData.user_email}
                  onChange={handleInputChange}
                  required
                />
              </div>

              {/* Hidden inputs for score, interpretation, and recommendation */}
              <input type="hidden" name="gad7_score" value={calculateTotalScore()} />
              <input type="hidden" name="gad7_interpretation" value={getScoreInterpretation(calculateTotalScore())} />
              <input type="hidden" name="gad7_recommendation" value={getRecommendation(calculateTotalScore())} />

              <button type="submit" className="gad7-submit-btn">{submitButtonText}</button>

              <div className='result-note'>
              <p>Please note that the information you provide will be handled with confidentiality and in accordance with our privacy policy. However, completing these assessments should not be considered a substitute for professional advice, diagnosis, or treatment. Always seek the advice of your physician or qualified mental health provider with any questions you may have regarding your mental health condition.</p>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  </div>
</div>
    </>
  );
};

export default GAD7;
