import React from 'react'
import InsuranceSection from '../insurance/InsuranceSection';
import ContactForm from '../contact/ContactForm';
// import MissionHome from '../mission/MissionHome';
import Section1 from '../section1/Section1';
// import LandingContact from '../contact/LandingContact';
// import BrainSliderSmall from '../brainSlider/BrainSliderSmall';
import Section2B from '../section2/Section2B';
// import Access from '../section2/Access';


const home = () => {
  return (
    <>
    {/* <LandingContact /> */}
    <Section1 />
    <Section2B />
    <InsuranceSection />
    {/* <MissionHome />
    <BrainSliderSmall /> */}
    <ContactForm />
    {/* <Access /> */}
    </>
  )
}

export default home
