import React from "react";
// import { Link as LinkRoll } from "react-scroll";
// import { Link } from 'react-router-dom';
import Zoom from 'react-reveal/Zoom';

import "./Hero.css";

import '../fonts/Gorgeous_Serif_Font.ttf'

const Hero = () => {
  return (
    <>
<figure>

	<div class="imagesContainer">

  <div className="hero-caption">

    <Zoom duration={1000}>
      <h1>Healing <span>Mind</span> </h1>
    </Zoom>
    <Zoom duration={3000}>
    <h1>TMS <span>Therapy</span></h1>
    </Zoom>

    <Zoom duration={4000}>
    <h2>Find out how TMS can help you overcome depression</h2>
    </Zoom>

    <Zoom duration={4500}>
    <p>"Your Journey to Mental Wellness Starts Here"</p>
    </Zoom>

    <Zoom duration={5000}>
    <a href="tel:3232825838"><h3>+1 (323) 282-5838</h3></a>
    </Zoom>

  </div>


		<div class="imageDiv image1">
    </div>
		<div class="imageDiv image2 fadeInClass"></div>
	</div>
</figure>
    </>
  );
};

export default Hero;
