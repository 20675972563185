import React, { useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import {Link} from 'react-router-dom'
import logo from '../../assets/nav-logo-2.png'
import { RiMenu3Line } from "react-icons/ri";

import './NavbarStyles.css'

const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleNav = () => setNav(!nav)

    return (
        <>
        <div className='navbar' name="top">

            <div className='nav-box '>

                <div className="navbar-logo">
                    <Link to='/'>
                            <img src={logo}  alt="Healing Mind" loading="lazy"/>
                    </Link>
                </div>

                <ul className={nav ? 'nav-menu active' : 'nav-menu'}>
                    <li><Link to='/'>Home</Link></li>
                    <li><Link to='/tms-therapy'> TMS Therapy </Link></li>
                    <li><Link to='/about-us'>About Us</Link> </li>
                    <li><Link to='/insurance'>Insurance</Link></li>
                    <li><Link to='/faq'>FAQs</Link></li>
                    <li><Link to='/contact'>Contact Us</Link></li>
                </ul>

                <div className="hamburger" onClick={handleNav}>
                    {!nav ? (<RiMenu3Line className='icon' />) : (<FaTimes className='icon' />)}
                </div>

            </div>

        </div>
        </>
    )
   
}

export default Navbar

